*{
  scroll-behavior: scroll !important;
  -webkit-scroll-behavior: scroll !important;
  /* -webkit-overflow-scrolling: touch !important; */
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
html, body{
  background-color: #0a0909 !important;
}
body::-webkit-scrollbar {
  display: none !important;
}
.mat-expansion-indicator::after{
  color:rgba(255, 255, 255, 0.8) !important;
}

.review-accordion .review-preview .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.review-accordion .review-preview .mat-expansion-panel-header{
  padding: 0;
}


